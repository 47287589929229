<template>
  <v-container fluid>
    <v-row>
      <v-col lg="3" md="6" cols="12">
        <riegenkarten :id="id" :e="e" :r="r" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <mannschaftsuebersicht :id="id" :e="e" :r="r" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <beteiligte :id="id" :e="e" :df="df" />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <besteturner :id="id" :e="e" :r="r" :df="df" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'

import gql from 'graphql-tag'
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'
import { usePdf } from '@/views/components/stb_m_2021/plugins/pdf'
import { useAuth } from '@/plugins/auth'

export default {
  name: 'lf',

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useGraphQL(context),
      ...useCalc(props, context),
      ...usePdf(context)
    }
  },

  components: {
    beteiligte: () => import('../../general/beteiligte'),
    Besteturner: () => import('../../general/besteturner'),
    riegenkarten: () => import('../../general/riegenkarten'),
    mannschaftsuebersicht: () => import('../../general/mannschaftsuebersicht')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    allteams () {
      return this.e?.teams || []
    }
  },

  methods: {
    abschliessen () {
      const isScore = this.mode(this.e) === 'wk_score4'

      this.allteams.forEach((t) => {
        const team = t.team._id
        const final = this.mannschaftgesamtergebnis(this.e, t.team._id)
        const score = this.mannschaftgesamtergebnisscore(this.e, t.team._id)
        const gp = this.mannschaftgesamtergebnisgp(this.e, this.df, t.team._id)
        const tp = this.mannschaftgesamtergebnistp(this.e, t.team._id)

        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!,
              $final: Float, $score: Int, $gp: Float, $tp: Float
              ) {
                StbM2021WkTeamUpdateResult(
                  id: $id, team: $team,
                  final: $final, score: $score, gp: $gp, tp: $tp
                ) { _id }
            }
          `,
          variables: {
            id: this.id,
            team,
            final,
            score: isScore ? score : null,
            gp,
            tp
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
